@tailwind base;
@tailwind components;

body {
  @apply .h-screen .bg-gray-100 font-maven;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-bebas;
}

.root-container {
  @apply .flex .flex-col .h-full;
}

select:focus {
  outline: none;
  border-color: #2dccd3;
}

input:focus {
  outline: none;
  border-color: #2dccd3;
}

.card {
  @apply max-w-lg w-full p-6 rounded-lg bg-white shadow-md;
}

.field {
  @apply rounded border py-2 px-3 text-gray-800 bg-white;
}
select.field {
  padding-right: 10px;
}

.field-label {
  @apply mb-2 uppercase text-sm text-gray-800;
}

.field-group {
  @apply .flex .flex-col .mb-4;
}

.field-pill {
  @apply text-gray-800 border-teal-600 border py-1 px-4 rounded flex-1;
}

.field-pill-selected {
  @apply bg-teal-600 border-teal-600 text-white;
}

.field-error {
  @apply text-red-400 mt-1;
}

.button {
  @apply bg-teal-500 text-white text-lg tracking-wider uppercase font-bold py-2 w-full rounded flex justify-center items-center font-bebas;
}

.button-variant {
  @apply bg-teal-600 text-white text-base tracking-wider font-bold py-2 w-full rounded flex justify-center items-center;
}

.button-disabled {
  @apply bg-gray-400;
  cursor: not-allowed;
}

@tailwind utilities;

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.underlined-header {
  position: relative;
  margin-bottom: 30px;
}

.underlined-header::after {
  height: 4px;
  display: block;
  content: ' ';
  background: #2dccd3;
  width: 80px;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-40px);
}

.terms-and-conditions {
  margin-top: 20px;
}

.href-link {
  font-weight: bold;
}
.href-link:hover {
  color: #2e2c44;
}

.powered-by-stripe {
  width: auto;
  height: 50px;
  margin: 20px auto;
}
.places-field-group {
  position: relative;
}
.autocomplete-dropdown-container {
  position: absolute;
  top: 100%;
  z-index: 10;
}
.field-hint {
  font-size: 12px;
  color: #bbb;
}

button.amount-pill:hover {
  background: #0198a9;
  color: #fff;
}

.others-amount {
  padding: 8px;
  padding-left: 4px;
  width: 100%;
}

input.others-amount:focus {
  outline-width: 0;
}

.amount-field-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  border: 1px solid #0198a9;
  border-radius: 4px;
}

.amount-field-left {
  padding: 10px 15px;
  border-right: 1px solid #0198a9;
}

.container {
  min-width: 300px;
  padding: 0px 5px;
}

.main {
  flex: 1.5;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header {
  font-size: 60px;
  font-weight: 500;
  color: #e5a76e;
}

.checkmark {
  max-width: 400px;
}

.text-content {
  text-align: center;
  margin: 20px 0px 40px 0px;
  font-size: 24px;
  max-width: 500px;
  line-height: 1.2;
}

.get-app-button {
  background-color: #0198a9;
  height: 40px;
  max-width: 300px;
  width: 100%;
  border-radius: 5px;
  color: #fff;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Bebas Neue;
}

.mobile-screens {
  width: 100%;
}

.screens-container {
  display: flex;
  align-items: center;
}

.bottom-container {
  margin: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bottom-text {
  font-size: 24px;
  margin-bottom: 20px;
  max-width: 500px;
  text-align: center;
  line-height: 1.2;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  min-height: 300px;
}

.mobile-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .header {
    font-size: 40px;
  }

  .text-content {
    font-size: 20px;
  }

  .bottom-text {
    font-size: 20px;
  }

  .checkmark {
    max-width: 300px;
  }
}

@media (max-width: 380px) {
  .header {
    font-size: 30px;
  }

  .text-content {
    font-size: 16px;
  }

  .bottom-text {
    font-size: 16px;
  }

  .checkmark {
    max-width: 200px;
  }

  .container {
    min-width: 200px;
  }

  .get-app-button {
    font-size: 20px;
  }
}
